/**
 * Created by Jachin on 2019/4/11.
 */
import React, { Component } from 'react'
import Loadable from 'react-loadable';
import {Loading} from 'element-react'
import PrivateRoute from './PrivateRoute'
import Header from '../components/Header'
import FooterToolbar from '../components/FooterToolbar'
import Index from '../views/index'

const PageLoading = function () {
  return <Loading fullscreen={true} text="拼命加载中"/>;
}
const Rules = Loadable({
  loader: () => import('../views/rules'),
  loading: PageLoading,
});
const Jobs = Loadable({
  loader: () => import('../views/jobs'),
  loading: PageLoading,
});
const JobDetail = Loadable({
  loader: () => import('../views/jobs/job-detail'),
  loading: PageLoading,
});
const JobEdit = Loadable({
  loader: () => import('../views/jobs/edit'),
  loading: PageLoading,
});
const Company = Loadable({
  loader: () => import('../views/company'),
  loading: PageLoading,
});
const CompanyDetail = Loadable({
  loader: () => import ('../views/company/company-detail'),
  loading: PageLoading,
});
const RecommendRecord = Loadable({
  loader: () => import('../views/recommend/recommend-record'),
  loading: PageLoading,
});
const RecommendCreate = Loadable({
  loader: () => import('../views/recommend/recommend-create'),
  loading: PageLoading,
});
const UserCenter = Loadable({
  loader: () => import('../views/user-center'),
  loading: PageLoading,
});
const ResumeList = Loadable({
  loader: () => import('../views/resume/resume-list/home'),
  loading: PageLoading,
});
const ResumeDetail = Loadable({
  loader: () => import('../views/resume/resume-detail'),
  loading: PageLoading,
});
const ResumeUpload = Loadable({
  loader: () => import('../views/resume/resume-upload'),
  loading: PageLoading,
});
const ResumeUploadPreview = Loadable({
  loader: () => import('../views/resume/resume-upload-preview'),
  loading: PageLoading,
});
const CommunicationRecord = Loadable({
  loader: () => import('../views/resume/communication-record'),
  loading: PageLoading,
});
const ResumeRecommendRecord = Loadable({
  loader: () => import('../views/resume/recommend-record'),
  loading: PageLoading,
});
const ProtectInfo = Loadable({
  loader: () => import('../views/protectInfo'),
  loading: PageLoading,
});

const SpecList = Loadable({
  loader: () => import('../views/spec'),
  loading: PageLoading,
});

const Financial = Loadable({
  loader: () => import('../views/financial'),
  loading: PageLoading,
});

class AppRouter extends Component {
  render () {
    return (
      <div>
        <Header/>
        <div style={{height: '60px'}}></div>
        <div className="content">
          <PrivateRoute path={`${this.props.match.path}/index`} component={Index} />
          <PrivateRoute path={`${this.props.match.path}/jobs`} exact component={Jobs} />
          <PrivateRoute path={`${this.props.match.path}/jobs/job-detail`} exact component={JobDetail} />
          <PrivateRoute path={`${this.props.match.path}/jobs/edit`} exact component={JobEdit} />
          <PrivateRoute path={`${this.props.match.path}/company`} exact component={Company} />
          <PrivateRoute path={`${this.props.match.path}/company/company-detail`} exact component={CompanyDetail} />
          <PrivateRoute path={`${this.props.match.path}/recommend/recommend-record`} component={RecommendRecord} />
          <PrivateRoute path={`${this.props.match.path}/recommend/recommend-create`} component={RecommendCreate} />
          <PrivateRoute path={`${this.props.match.path}/user-center`} exact component={UserCenter} />
          <PrivateRoute path={`${this.props.match.path}/resume/resume-list`} component={ResumeList} />
          <PrivateRoute path={`${this.props.match.path}/resume/resume-detail`} component={ResumeDetail} />
          <PrivateRoute path={`${this.props.match.path}/resume/resume-upload`} component={ResumeUpload} />
          <PrivateRoute path={`${this.props.match.path}/resume/resume-upload-preview`} component={ResumeUploadPreview} />
          <PrivateRoute path={`${this.props.match.path}/resume/communication-record`} component={CommunicationRecord} />
          <PrivateRoute path={`${this.props.match.path}/resume/recommend-record`} component={ResumeRecommendRecord} />
          <PrivateRoute path={`${this.props.match.path}/protect-info`} component={ProtectInfo} />
          <PrivateRoute path={`${this.props.match.path}/rules`} component={Rules} />
          <PrivateRoute path={`${this.props.match.path}/spec/:id`} component={SpecList} />
          <PrivateRoute path={`${this.props.match.path}/financial`} component={Financial} />
        </div>
        <FooterToolbar/>
      </div>
    )
  }
}

export default AppRouter;






