import React, { useEffect, useState } from "react";
import { Form, Row, Col, Cascader, Input, Select, Spin,Alert } from "antd";
import SelectGender from "../../../../components/SelectGender";
import CustomButton from "../../../../components/CustomButton";
import CustomToast from "../../../../components/CustomToast";
import http from "../../../../api/http";
import api from "../../../../api/index";
import "./index.scss";
import { setUserInfo } from '@/store/userSlice'
import store from '@/store'

function flattenData(data, parentPath = "") {
  const result = [];
  function traverse(node, currentPath) {
    const path = currentPath ? `${currentPath},${node.code}` : `${node.code}`;
    result.push({
      ...node,
      path,
    });
    //if(node.level <3 ) node.disabled = true;
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => traverse(child, path));
    }
  }
  data.forEach((node) => traverse(node, parentPath));
  return result;
}
const validateName = (_, value) => {
  if (value && value.includes('用户')) {
    return Promise.reject(new Error("请填写真实姓名!"));
  }
  return Promise.resolve();
};

export default function UserBaseInfoEdit(props) {
  const [dictionaries, setDictionaries] = useState({
    goodAtIndustry: [],
    goodAtPosition: [],
  });
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();

  const fetchDic = () => {
    setLoading(true);
    const fetchIndustry = async () => {
      const res = await http.get(api.getAllDic);
      if (res.code === 200) {
        const data = res.data || [];
        return data.find((d) => d.code === 300)?.children || [];
      }
      return [];
    };
    const fetchPositions = async () => {
      const res = await http.get(api.getTreeAll());
      if (res.code === 200) {
        return res.data || [];
      }
      return [];
    };
    Promise.all([fetchIndustry(), fetchPositions()]).then(
      ([goodAtIndustry, goodAtPosition]) => {
        //转换后端传进来的路径,还原给表单所需要的格式
        const goodJobArr = [];
        if (Array.isArray(props?.data.goodJobArr)) {
          const flatGoodAtPosition = flattenData(goodAtPosition);
          props.data.goodJobArr.forEach((code) => {
            const node = flatGoodAtPosition.find((item) => item.code === code);
            const pathArray = node.path.split(",").map((i) => Number(i));
            goodJobArr.push(pathArray);
          });
        }
        form.setFieldValue("goodJobArr", goodJobArr);
        setLoading(false);
        setDictionaries({
          goodAtIndustry,
          goodAtPosition,
        });
      }
    );
  };
  const updateUserInfo = ({ name, gender, goodDirectionArr, goodJobArr }) => {
    const goodJobFlatArray = goodJobArr.map(
      (subArray) => subArray[subArray.length - 1]
    );
    const formData = {
      name,
      gender,
      goodDirection: goodDirectionArr.toString(),
      goodJob: goodJobFlatArray.toString(),
    };
    setLoading(true);
    http
      .put(api.registerSelf, formData)
      .then((res) => {
        setLoading(false);
        if (res.code === 200) {
          props.onEdited();
          store.dispatch(setUserInfo({...props.data,name}));
        } else {
          CustomToast(res.message);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleSubmit = () => {
    form.submit();
  };
  useEffect(() => {
    fetchDic();
  }, []);
  return (
    <Spin spinning={loading}>
      <div className="user-center-block">
        {!props.hideTitle && <h5>基本信息</h5> }
        <Form
          labelWidth="120"
          initialValues={props.data || {}}
          form={form}
          onFinish={updateUserInfo}
          size="large"
          loading={loading}
        >
          <Row gutter="40">
            <Col span="12">
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: "必填" },{ validator: validateName }]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="性别" name="gender">
                <SelectGender />
              </Form.Item>
            </Col>
            <Col className="line" span="12">
              <Form.Item
                label="擅长行业"
                name="goodDirectionArr"
                rules={[
                  { required: true, message: "必填" },
                  { type: "array", max: 3, message: "最多选择3个" },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="最多选三个"
                  options={dictionaries.goodAtIndustry.map((d) => ({
                    label: d.name,
                    value: d.code,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col className="line" span="12">
              <Form.Item
                label="擅长职位"
                name="goodJobArr"
                rules={[
                  { required: true, message: "必填" },
                  { type: "array", max: 3, message: "最多选择3个" },
                ]}
              >
                <Cascader
                  popupClassName="user_baseinfo_edit_cascader_popup"
                  options={dictionaries.goodAtPosition}
                  fieldNames={{
                    label: "name",
                    value: "code",
                    children: "children",
                  }}
                  placeholder="最多选三个"
                  multiple
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="bottom-button-wrap">
            {!props.hideCancel && (
              <CustomButton
                type="gray"
                onClick={() => props.editBaseInfo(false)}
              >
                取消
              </CustomButton>
            )}
            <CustomButton onClick={handleSubmit} loading={false}>
              保存
            </CustomButton>
          </div>
        </Form>
      </div>
    </Spin>
  );
}
