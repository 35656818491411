import React from 'react';
import { Result } from 'antd';

export default function ErrorPage(){
  return(
    <Result
        status="error"
        title={`不支持微信浏览器，请点击右上角的“使用默认浏览器打开”`}
    />
  )
};
