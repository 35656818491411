import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

export function showContractAlert({signState,accountType}){
    const title = '提示';
    if(accountType ===1){
        Modal.confirm({
            title,
            icon: <ExclamationCircleFilled />,
            content: signState === 1 ? '合同正在签署中，请耐心等待' : '您的账号还未签约,请先去签约。',
            okText: signState === 1 ? '查看进度' : '去签约',
            cancelText: '取消',
            onOk() {
                localStorage.setItem('userCurrentTab','8');
                window.location.href='/#/home/user-center';
            },
        });
    }
    else{
        Modal.info({
            title,
            okText: '知道了',
            content: signState === 1 ? '合同正在签署中，请耐心等待' : '您的账号还未签约,请提醒主账号去签约。',
        });
    }
}