import React from 'react';
import ReactDOM from 'react-dom';
import zhCN from "antd/locale/zh_CN";
import { ConfigProvider } from "antd";
import App from './App';
import './css/style.scss'
import 'element-theme-default';
import './extend/index'
import store from './store'
import { Provider } from 'react-redux'
import ErrorPage from './Error';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

function isWeChatBrowser() {
  return /MicroMessenger/i.test(navigator.userAgent);
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      {isWeChatBrowser () ? <ErrorPage /> : <App />}
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'));

