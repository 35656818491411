import React, { useEffect, useRef,useState } from 'react';
import http from "@/api/http";
import api from "@/api";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import './image.scss';

function ImagesList({history}){
    const containerRef = useRef(null);
    const [data,setData] = useState([]);
    const visibleCount = 4, itemWidth = 300;
    const gap = 30;
    const showArrows = data.length > visibleCount;
    const scroll = (direction) => {
      if (containerRef.current) {
        const scrollAmount = direction === "left" ? -itemWidth : itemWidth;
        containerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    };
    const fetchImages = async() =>{
        try{
            const {code,data} = await http.get(api.specialImgs());
            if(code === 200){
                setData(data);
            }
        }
        catch(e){

        }
    }
    useEffect(()=>{
        fetchImages();
    },[])
    return(
        <div className="image-carousel">
            <div
                ref={containerRef}
                className='image-carousel-container'
            >
                {data.map(({id:index,imageUrl:src,title,sencondTitle}) => (
                    <div 
                        className='image-carousel-item' 
                        style={{ width: itemWidth, height: "auto", marginRight: gap }}
                        onClick={()=>{window.open(`/#/home/spec/${index}`)}}
                    >
                        <div className='image-carousel-item-content'>
                            <div className='t1'>{title}</div>
                            <div className='t2'>{sencondTitle}</div>
                        </div>
                       <img
                            key={index}
                            src={src}
                            alt={`img-${index}`}
                        />
                    </div>
                ))}
            </div>
            <div className='image-carousel-opt'>
                {showArrows && (
                    <>
                        <span 
                            onClick={() => scroll("left")} 
                            className="image-carousel-button left"
                        >
                            <LeftOutlined />
                        </span>
                        <span 
                            onClick={() => scroll("right")} 
                            className="image-carousel-button right"
                        >
                            <RightOutlined />
                        </span>
                    </>
                )}
            </div>

      </div>
    )
}
export default withRouter(ImagesList);