/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { showContractAlert } from '@/utils/ui';
import { Modal,message } from 'antd';
import UserBaseInfoEdit from '@/views/user-center/components/BaseInfoEdit';
/**
 * 检查用户是否签署了合同，没签合同强制弹窗
 */
function withContractGuard(WrappedComponent) {
  return function GuardedComponent(props) {
    const user = useSelector((state) => state.user?.userInfo) || {};
    const userIsNull = !user || Object.keys(user).length === 0;
    const ref = React.createRef();

    useEffect(() => {
        const {signState,accountType} = user;
        if(!userIsNull){
            if(!ref.current && signState!==2){
              ref.current = true;
              showContractAlert({signState,accountType})
            }
            else if(user.name.includes('用户')){
              console.log('用户信息:',user);
              const instance = Modal.info({
                  title:'个人信息完善',
                  content:(
                    <UserBaseInfoEdit 
                      data={user} 
                      onEdited={()=>{message.success('修改成功!',2,()=>instance.destroy())}} 
                      hideCancel={true} 
                      hideTitle={true} 
                    />
                  ),
                  width:800,
                  footer:null
                })
            }
        }
    }, [user,userIsNull]);
    
    return <WrappedComponent {...props} user={user || {}} />;
  };
}

export default withContractGuard;
