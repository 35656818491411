/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * 职位列表 item 组件
 * */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import './index.scss'
import CustomToast from '../../components/CustomToast'
import SplitLine from '../../components/SplitLine'
import http from "../../api/http";
import api from "../../api";
import {Tooltip,Button, Badge,Popover} from "element-react";
import CollectPosition from '../CollectPositionDIalog'
import classNames from 'classnames';
import JobShare from '../JobShare';
import { getQueryParamNew } from '../../utils';
import { showContractAlert } from '@/utils/ui';

class JobItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collectSubmitLoading: false,
      dialogVisible:false,
      cancleDialogVisible:false,
      dialogType:1,   //1-接单弹窗，2-取消接单弹窗
    }
  }
  // 立即推荐
  handleRecommend = () => {
    if (this.props.userInfo?.signState !== 2) {
      const {signState,accountType} = this.props.userInfo;
      showContractAlert({signState,accountType});
      return
    }
    window.location.hash = `#/home/recommend/recommend-create?recommendType=2&positionId=${this.props.data.id}&companyId=${this.props.data.companyId}`;
  }
  // 前往公司详情页
  toCompanyHome = () => {
    window.open(`#/home/company/company-detail?companyId=${this.props.data.companyId}`, '_blank');
  }

  // 前往职位详情
  toPositionDetail = () => {
    if (this.props.customerClick && typeof this.props.customerClick === 'function') {
      this.props.customerClick(this.props.data.id)
      return
    }
    window.open(`#/home/jobs/job-detail?id=${this.props.data.id}`, '_blank');
  }
  // 前往职位详情定位到需求分析
  toPositionDetailRequire = () => {
    window.open(`#/home/jobs/job-detail?id=${this.props.data.id}&active=3`, '_blank');
  }
  // 过滤发布时间
  filterDate = (date) => {
    return new Date(date).Format('MM/dd hh:mm')
  }
  // 点击收藏和取消收藏
  collect = async () => {
    if (!this.props.data.collectionPosition) {
      if (this.props.userInfo?.signState !== 2) {
        const {signState,accountType} = this.props.userInfo;
        showContractAlert({signState,accountType});
        return
      }
    }
    if (this.state.collectSubmitLoading) {
      return false
    }

    this.setState({
      collectSubmitLoading: true,
      dialogVisible:true,
      dialogType: this.props.data.collectionPosition?2:1
    });
  }

  //确定收藏职位
  collectConfirm = async ()=>{
    let res = await http.post(api.collect, {}, {
      params: {
        positionSerialNo: this.props.data.serialNo
      }
    });
    if (res.code === 200 && this.props.callback) {
      CustomToast("你已接单成功！");

    }else{
      CustomToast(res.message)
    }
    this.setState({
      collectSubmitLoading: false,
      dialogVisible:false
    });

  }


  //取消收藏职位
  canclecollectConfirm = async()=>{
    let res = await http.delete(api.collectDel(this.props.data.collectorNumber));
      if (res.code === 200 && this.props.callback) {
        CustomToast("取消接单成功！");
        let cbData = {
          type: false,
          collectorNumber: ''
        }
        this.props.callback(cbData, this.props.data)
      }else{
        CustomToast(res.message);
      }
      this.setState({
        collectSubmitLoading: false,
        cancleDialogVisible:false
      });

  }

  //关闭弹窗的回调
  cancleDialog = async()=>{
    this.setState({
      dialogVisible: false,
      collectSubmitLoading:false
    })
  }

  //接单，取消接单成功的回调
  collectDealSuccess=async(cbData, datas)=>{
    this.props.callback(cbData, datas);
  }
  showRecommendBtns = () => {
      const btnProps = {};
      if(!this.props.tinyToolbar){
        btnProps.type = 'primary';
      }
      else {
        btnProps.size = 'small'
      }
      if(this.props.showRecommend){
        return(
          <>
            <Button onClick={this.collect} icon={this.props.data.collectionPosition ? 'star-on' : 'star-off'} {...btnProps}>
              {
              this.props.data.collectionPosition ? '已接单' : '立即接单'
              }
            </Button>
            <Button onClick={this.handleRecommend} icon='upload2' {...btnProps}>立即推荐</Button>
          </>
        )
      }
  }
  render () {
    const {showCompanyName = true} = this.props;
    const clearInfo = (()=>{
      if(this.props.userInfo?.signState === 2) return true;
      const ui = getQueryParamNew('ui');
      return ui == 2;
    })();
    if (this.props.data) {
      return (
        <div className="job-card">
          { this.props.data.headhunterSignResourceDesc && <p className='headhunterSignResourceDesc'>{this.props.data.headhunterSignResourceDesc}</p> }
          <div className="right-content">
            <div className="top">
              {!this.props.tinyToolbar && <div className='recommend-btn'>{this.showRecommendBtns()}</div>}
              {/******头像****** */}
              {
                (this.props.showActionBtn && !this.props.hideAvatar) && (
                  <div className="left-content">
                    <div className={classNames('logo',{'text-dim':!clearInfo})}>
                      <img src={this.props.data.avatarUrl || require('../../images/default.png')} alt=""
                          className="img"/>

                    </div>
                  </div>
                )
              }
              <div className="job-info">
                <div className="row01" onClick={this.toPositionDetail}>
                  <Tooltip className="item-name" effect="dark" content={this.props.data.name} placement="bottom-start" style={this.props.tinyToolbar && {maxWidth:210}}>
                    <p className="name">{this.props.data.name}</p>
                  </Tooltip>
                  <div style={{display:'inline-flex'}}>
                  { this.props.data.payMode && <p className="salary-tag">{this.props.data.payMode === 'ANNUAL_SALARY' ? '年薪' : '月薪'}</p>}
                  {
                      this.props.data.cooperateTypeName && <p className="salary-tag-coo"> <Tooltip className="item" effect="dark" content="与雇主的合作模式为：RPO/猎头" placement="bottom">{this.props.data.cooperateTypeName}</Tooltip></p>
                  }

                    {this.props.data.headhunterPositionLabels && this.props.data.headhunterPositionLabels.map(value => <p
                      className={`position-tag ${value.labelCode == 1 ? 'position-urgentCall' : value.labelCode == 2 ? 'position-quick' : ''}`}>{value.labelName}</p>)}
                    {this.props.data.companyLevelName && (this.props.data.level == 1 || this.props.data.level == 2) &&
                      <p className='position-tag position-ka'>{this.props.data.companyLevelName}</p>}
                    {this.props.data.newPosition && <p className="position-tag position-new">新职位</p>}


                    {/*<p className={`${this.props.data.priority === 0 ? 'urgent-tag-0' : ''}${this.props.data.priority === 3 ? 'urgent-tag-1' : ''}${this.props.data.priority === 6 ? 'urgent-tag-2' : ''}${this.props.data.priority === 9 ? 'urgent-tag-3' : ''}`}>{this.props.data.priorityDesc}</p>*/}
                    </div>
                  </div>
                  <div className="row02">
                    <p className="item"><strong> {this.props.data.salaryRangeName}</strong></p>
                    <p className="item">{this.props.data.city}</p>
                    <p className="item">招{this.props.data.workeNumbers}人</p>
                    <p className="item">{this.props.data.qualificationsName}</p>
                    <p className="item">{this.props.data.timeDesc}</p>
                  </div>
                  {showCompanyName && (
                    <div className="company-info" onClick={this.toCompanyHome}>
                        <p className={classNames("company-name",{'text-dim':!clearInfo})}>{this.props.data.companyName}</p>
                    </div>
                  )}
                  {/*<div className="row03">职位编号：{this.props.data.serialNo}</div>*/}
              </div>
            </div>
            <SplitLine margin={'5px 0'}/>
            <div className="bottom">
              <div className="left">
                <div className="commission">
                  <p className="text mr-20">
                    <span className="mr-10">
                      职位负责人：{this.props.data.positionChargeName}
                    </span>
                    <span>
                    <Popover placement="bottom" width="237" trigger="hover" content={<img src={this.props.data.positionChargeScanUrl} alt='' />}>
                        <img src={this.props.data.positionChargeScanUrl} alt="" className={'qr-code'}/>
                    </Popover>
                    </span>
                  </p>
                  <p className="text red flex-center">
                    <span>
                      预估佣金：{
                      this.props.data.expectCost !== 0 && this.props.data.maxExpectCost !== 0 ?
                        `${this.props.data.expectCost}K - ${this.props.data.maxExpectCost}k` :
                        (this.props.data.expectCost !== 0 ?
                          `${this.props.data.expectCost}K以上` :
                          `${this.props.data.maxExpectCost}K以下`)
                    }
                    </span>
                    {
                      this.props.data.guaranteePeriod ? <span>
                      &nbsp;| {this.props.data.guaranteePeriod}个月保证期
                    </span> : null
                    }

                  </p>
                  {/* Badge组件isDot=false 显示有bug,不得已这样写 */}

                  {
                    this.props.showActionBtn && (
                      this.props.data.previewRequired ? (
                        <Badge isDot={this.props.data.previewRequired}>
                          <span className={classNames('color-primary','mr-10')} style={{cursor: 'pointer'}} onClick={this.toPositionDetailRequire}>
                            需求分析更新&gt;
                          </span>
                        </Badge>
                      ) : (
                        <span className={classNames('color-primary','mr-10')} style={{cursor: 'pointer'}} onClick={this.toPositionDetailRequire}>
                          需求分析更新&gt;
                        </span>
                      )
                    )
                  }
                </div>
                {!this.props.tinyToolbar && <JobShare id={this.props.data.id}  /> }
              </div>
            </div>
            { this.props.tinyToolbar && (
              <div className="jobitem-card-toolbar">
                {
                  this.showRecommendBtns()
                }
                  <JobShare id={this.props.data.id} btnSize='small' btnType='default' />
              </div>)}

          </div>

          <CollectPosition
            data={this.props.data}
            dialogVisible={this.state.dialogVisible}
            onCancel={this.cancleDialog}
            dialogType={this.state.dialogType}
            callback={this.collectDealSuccess}
          ></CollectPosition>
          {
            this.props.children
          }
        </div>
      )
    } else {
      return <div>没有数据</div>
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state?.user?.userInfo || {},
  };
};
export default connect(mapStateToProps)(JobItem);
