/**
 * Created by Jachin on 2019/4/11.
 */
import React, { Component } from 'react'
import './index.scss'
import CustomToast from '../../components/CustomToast'
import ContentWrapper from '../../components/ContentWrapper'
import PositionDetailDrawer from '../../views/jobs/components/PositionDetailModal'
import JobItem from '../../components/JobItem'
import { Form, Input, Pagination, Select, Loading,Dialog,Button, Checkbox } from 'element-react'
import http from "../../api/http";
import api from "../../api";
import SplitLine from "../../components/SplitLine";
import { Boolean2Int } from '../../utils/index';
import {Row,Col} from 'antd';
import withContractGuard from '@/components/WithContractGuard'
import ImagesList from './images'
class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      drawVisible: false,
      positionId: '',
      dictionaries: {
        key104: [],
        key106: [],
        key230: [],
        key288: [],
        key300: []
      },
      positionArea: [],
      positionTag: [],
      searchForm: {
        information: '',// 关键字
        companyName: '',
        city: '',// 城市信息
        effectiveStart: null,// 有效发布时间
        industryType: '',// 行业分类
        payMode: 'ANNUAL_SALARY',// 薪资模式选择
        maximum: '',// 月最高薪资
        minimum: '',// 月最低薪资
        salaryMaximum: '',// 年最高薪资
        salaryMinimum: '',// 年最低薪资
        qualificationsType: '',// 学历要求
        positionLabel: '', // 职位标签
        queryDate: '', // 发布日期
        priorityHeadhunter:'', //优先级
        pageIndex: 1,
        pageSize: 10,
        customerType: false, // 是否KA客户
        urgentHeadhunter: false, // 是否加急
        overseasPosition: false // 是否海外
      },
      searchFormShow: {// 用于对应字段的页面显示
        industryTypeName: '',
        payModeName: '年薪',
        qualificationsTypeName: '',
      },
      data: [],
      pagination: {},
      pageLoading: false,// 页面加载中
      isCollect: 1, // 是否是收藏职位 1 普通，2 收藏
      pageDialogVisible:true,
      showHotVisible:false
    }
  }


  componentDidMount () {
    this.search()
    this.getDictionaries()
    this.getPositionArea()
  }

  // 表单字段更新
  inputChange(form, key, value) {
    console.log(this.state.dictionaries.key230)
    this.setState({
      [form]: Object.assign({}, this.state[form], {[key]: value })
    }, () => {
      // 上面的代码改成array.includes
      if (['city', 'effectiveStart', 'positionLabel', 'queryDate', 'customerType', 'urgentHeadhunter', 'overseasPosition'].includes(key)) {
        this.search()
      }
    });
  }

  getData = (params, type) => {
    this.setState({
      pageLoading: true
    });
    http.get(api.positionList, {
      params
    })
      .then(res => {
        if (res.code === 200 ) {
          this.setState({
            data: res.data,
            pagination: res.pagination || {}
          }, () => {
            if (type === 'page') {
              this.scrollTop(300, 200)
            }
          })
        } else {
          CustomToast(res.message)
        }
        this.setState({
          pageLoading: false
        });
      })
  }
  formSubmit = (event) => {
    event.preventDefault();
    this.search();
  }
  // 根据条件查询
  search = (type) => {
    let data = {};
    let searchForm = this.state.searchForm;
    for (let key in searchForm) {
      if (searchForm[key]) {
        data[key] = searchForm[key];
      }
    }
    if (data.effectiveStart) {
      data.effectiveStart = data.effectiveStart.Format('yyyy-MM-dd hh:mm:ss')
    }
    data.customerType = Boolean2Int(data.customerType)
    data.urgentHeadhunter = Boolean2Int(data.urgentHeadhunter)
    data.overseasPosition = Boolean2Int(data.overseasPosition)
    if (data.payMode === 'ANNUAL_SALARY') {
      delete data.maximum;
      delete data.minimum;
    } else if (data.payMode === 'MONTHLY_SALARY') {
      delete data.salaryMaximum;
      delete data.salaryMinimum;
    }
    if (!data.maximum && !data.minimum && !data.salaryMaximum && !data.salaryMinimum) {
      delete data.payMode;
    }
    if (type !== 'page') {
      data.pageIndex = 1;
    }
    if (this.state.isCollect === 1 || this.state.isCollect === '') {
      this.getData(data, type)
    } else {
      this.getCollectData(data, type)
    }
  }

  // 重置搜索
  resetSearch = async () => {
    await this.setState({
      searchForm: {
        information: '',// 关键字
        companyName: '',
        city: '',// 城市信息
        effectiveStart: null,// 有效发布时间
        industryType: '',// 行业分类
        payMode: 'ANNUAL_SALARY',// 薪资模式选择
        maximum: '',// 月最高薪资
        minimum: '',// 月最低薪资
        salaryMaximum: '',// 年最高薪资
        salaryMinimum: '',// 年最低薪资
        qualificationsType: '',// 学历要求
        queryDate: '', // 发布日期
        positionLabel: '', // 职位标签
        pageIndex: 1,
        pageSize: 10,
      },
      searchFormShow: {// 用于对应字段的页面显示
        industryTypeName: '',
        payModeName: '年薪',
        qualificationsTypeName: '',
      },
      isCollect:1
    });
    this.search();
  }

  // 分页发生变更
  currentChange = (pageIndex) => {
    this.setState({
      searchForm: {
        ...this.state.searchForm,
        pageIndex
      }
    }, this.search.bind(this, 'page'))
  }

  // 回车事件
  inputKeyUp = (ev) => {
    if (ev.keyCode === 13) {
      this.search();
    }
  }

  // 需要获取的字典
  getDictionaries = () => {
    let parentCodes = [104, 106, 230,288, 300];
    parentCodes.forEach(value => {
      http.get(api.dictionaries(value))
        .then(res => {
          if (res.code === 200) {
            this.setState({
              dictionaries: {
                ...this.state.dictionaries,
                [`key${value}`]: res.data
              }
            })
          }
        })
    })
  }

  // 职位地址
  getPositionArea = () => {
    http.get(api.positionArea)
      .then(res => {
        if (res.code === 200) {
          this.setState({
            positionArea: res.data
          })
        }
      })
  }

  // 获取收藏职位
  getCollectData = (params, type) => {
    this.setState({
      pageLoading: true
    });
    http.get(api.collectAll, {
      params
    })
      .then(res => {
        if (res.code === 200 ) {
          this.setState({
            data: res.data,
            pagination: res.pagination || {}
          }, () => {
            if (type === 'page') {
              this.scrollTop(300, 200)
            }
          })
        } else {
          CustomToast(res.message)
        }
        this.setState({
          pageLoading: false
        });
      })
  }

  // 切换全部职位和收藏职位
  handleCollect = (type) => {
    this.setState({
      isCollect: type,
      searchForm: {
        ...this.state.searchForm,
        priorityHeadhunter:''
      }
    }, () => {
      this.search()
    })
  }

  //按优先级查询
  handlePriority = (type) => {
    this.setState({
      isCollect: '',
      searchForm: {
        ...this.state.searchForm,
        priorityHeadhunter:type
      }
    }, () => {
      this.search()
    })
  }

  // 切换dropdown类型的查询条件
  dropdownChange = (type, command) => {
    switch (type) {
      case 'industryType':
        this.state.dictionaries.key106.forEach(value => {
          if (value.name === command) {
            this.setState({
              searchForm: {
                ...this.state.searchForm,
                industryType: value.code,
              },
              searchFormShow: {
                ...this.state.searchFormShow,
                industryTypeName: value.name
              },
            }, this.search)
          }
        });
        break;
      case 'qualificationsType':
        this.state.dictionaries.key288.forEach(value => {
          if (value.name === command) {
            this.setState({
              searchForm: {
                ...this.state.searchForm,
                qualificationsType: value.code,
              },
              searchFormShow: {
                ...this.state.searchFormShow,
                qualificationsTypeName: value.name
              },
            }, this.search)
          }
        });
        break;
      case 'payMode':
        let searchFormShow = this.state.searchFormShow;
        if (command === 'MONTHLY_SALARY') {
          searchFormShow.payModeName = '月薪'
        } else if (command === 'ANNUAL_SALARY') {
          searchFormShow.payModeName = '年薪'
        }
        this.setState({
          searchFormShow,
          searchForm: {
            ...this.state.searchForm,
            payMode: command,
          },
        });
        break;
      default:
    }
  }

  // 职位收藏回调，true 收藏，false，取消收藏
  JobCollectCb = (cbData, item) => {
    let data = this.state.data.map(value => {
      if (value.id === item.id) {
        value.collectionPosition = cbData.type;
        value.collectorNumber = cbData.collectorNumber;
      }
      return value
    })
    this.setState({
      data
    })
  }

  // 滚动到指定位置
  scrollTop = (number = 0, time) => {
    if (!time) {
      document.body.scrollTop = document.documentElement.scrollTop = number;
      return number;
    }
    const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
    let spacingInex = time / spacingTime; // 计算循环的次数
    let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
    let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
    let scrollTimer = setInterval(() => {
      if (spacingInex > 0) {
        spacingInex--;
        this.scrollTop(nowTop += everTop);
      } else {
        clearInterval(scrollTimer); // 清除计时器
      }
    }, spacingTime);
  }


  //热招岗位
  showHot=()=>{
    this.setState({
      showHotVisible:true
    })
  }
  customerClick = (id) => {
    this.setState({
      positionId: id,
      drawVisible: true
    })
  }

  render () {
    const { drawVisible, positionId } = this.state;
    return (
      <div className='page-jobs'>
        <PositionDetailDrawer
          visible={drawVisible}
          positionId={positionId}
          onClose={() => this.setState({ drawVisible: false })}
        />
        <ContentWrapper>
          <div className="search-top">
            <Form inline={true} model={this.state.searchForm} onSubmit={this.formSubmit.bind(this)}>
              <Form.Item>
                <Input value={this.state.searchForm.companyName} placeholder="公司名称" onChange={this.inputChange.bind(this, 'searchForm', 'companyName')}></Input>
              </Form.Item>
              <Form.Item>
                <Input value={this.state.searchForm.information} placeholder="输入职位名称/公司名称进行搜索" onChange={this.inputChange.bind(this, 'searchForm', 'information')}></Input>
              </Form.Item>
              <Form.Item>
                <Select value={this.state.searchForm.industryType} onChange={this.inputChange.bind(this, 'searchForm', 'industryType')} placeholder="所属行业">
                  {
                    this.state.dictionaries.key300.map(value => (
                      <Select.Option key={value.code} value={value.code} label={value.name}></Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
              <Form.Item>
                <Select value={this.state.searchForm.city} onChange={this.inputChange.bind(this, 'searchForm', 'city')}
                        placeholder="工作地点">
                  {
                    this.state.positionArea.map(value => (
                      <Select.Option key={value.code} value={value.code} label={value.name}></Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
              <Form.Item>
                <Button nativeType="submit" type="primary">查询</Button>
                <Button onClick={this.resetSearch}>重置</Button>
              </Form.Item>
            </Form>

          </div>
          <div className="search-area">
            <p className="name">订单类型：</p>
            <Checkbox onChange={this.inputChange.bind(this, 'searchForm', 'overseasPosition')}
                      checked={this.state.searchForm.overseasPosition}>海外职位</Checkbox>
            <Checkbox onChange={this.inputChange.bind(this, 'searchForm', 'customerType')}
                      checked={this.state.searchForm.customerType}>KA客户</Checkbox>
            <Checkbox onChange={this.inputChange.bind(this, 'searchForm', 'urgentHeadhunter')}
                      checked={this.state.searchForm.urgentHeadhunter}>急招</Checkbox>
          </div>
          <div className="search-area">
            <p className="name">发布日期：</p>
            <p className={`item${this.state.searchForm.queryDate === '' ? ' active' : ''}`}
               onClick={this.inputChange.bind(this, 'searchForm', 'queryDate', '')}>全部</p>
            {
              this.state.dictionaries.key230.map(value => (
                <p className={`item${this.state.searchForm.queryDate === value.code ? ' active' : ''}`} key={value.code}
                   onClick={this.inputChange.bind(this, 'searchForm', 'queryDate', value.code)}>{value.name}</p>
              ))
            }
          </div>
          <div className="search-area">
            <p
              className={`item hasBorder ${this.state.isCollect === 1 ? 'active' : ''}`}
              onClick={this.handleCollect.bind(this, 1)}
            >全部职位列表</p>
            <p
              className={`item hasBorder ${this.state.isCollect === 2 ? 'active' : ''}`}
              onClick={this.handleCollect.bind(this, 2)}
            >我接单的职位</p>
            <p
              className={`item hasBorder ${this.state.searchForm.priorityHeadhunter === 1 ? 'active' : ''}`}
              onClick={this.handlePriority.bind(this, 1)}
            >优先级1</p>
            <p
              className={`item hasBorder ${this.state.searchForm.priorityHeadhunter === 2 ? 'active' : ''}`}
              onClick={this.handlePriority.bind(this, 2)}
            >优先级2</p>
            <p
              className={`item hasBorder ${this.state.searchForm.priorityHeadhunter === 3 ? 'active' : ''}`}
              onClick={this.handlePriority.bind(this, 3)}
            >优先级3</p>
            <p
              className={`item hasBorder ${this.state.searchForm.priorityHeadhunter === 4 ? 'active' : ''}`}
              onClick={this.handlePriority.bind(this, 4)}
            >优先级4</p>
          </div>
          <SplitLine />
          <ImagesList />
          <div className="page-content">

            <div className="block-left">
              <Loading loading={this.state.pageLoading}>
                <Row gutter={10}>
                {
                  this.state.data.map(value => <Col span={12}><JobItem showActionBtn showRecommend key={value.id} data={value} callback={this.JobCollectCb} customerClick={this.customerClick} tinyToolbar={true}/></Col>)
                }
                {
                  this.state.data.length === 0 && <div className="empty-tips">暂无数据</div>
                }
                </Row>
              </Loading>
              {
                this.state.data.length > 0 && (
                  <div>
                    <div className="block-pagination">
                      <Pagination layout="prev, pager, next, total, jumper" total={this.state.pagination.totalRow}
                                  pageSize={this.state.searchForm.pageSize} currentPage={this.state.pagination.pageIndex}
                                  onCurrentChange={this.currentChange}/>
                    </div>

                  </div>

                )
              }
            </div>
          </div>
        </ContentWrapper>

        {/* 热招 */}
        <Dialog
          title="关于分享职位和接单的说明"
          visible={this.state.showHotVisible}
          onCancel={() => this.setState({showHotVisible: false})}
          lockScroll={false}
          customClass='dialog-share-box'
        >

          <Dialog.Body>

            <div className='dialog-notice'>
              {/* <h4 className="">什么是热招岗位？（关于热招岗位）</h4> */}
              <div className='dialog-content'>

                <p className="hotBox-title">1.关于分享职位</p>
                <p className="hotBox-texts">1.1
                  点击分享职位按钮，可以将生成的图片发送至微信群、微信好友或者QQ群、QQ好友，以增加该职位的曝光量，可以更快速的找到合适的候选人。 </p>
                <p className="hotBox-texts">1.2 通过图片分享的职位候选人投递，该候选人是默认视为是当前分享人推荐的。</p>
                <p className="hotBox-texts">1.3
                  方便职位分享，不用再到处保存文字性的职位描述、或者其他形式，在一定程度上方便了职位的推荐。</p>


                <p className="hotBox-title">2.关于接单职位</p>
                <p className="hotBox-texts">2.1 接单职位以后，职位内容发生变化、需求发生变化都会给到邮箱和短信提醒;</p>
                <p className="hotBox-texts">2.2 如果不想接受消息，可以取消接单职位;</p>
                <p className="hotBox-texts">2.3 取消接单以后，依然可以推荐简历，但是职位的相关变化不会给予相关提醒。</p>
              </div>
            </div>

          </Dialog.Body>
          <Dialog.Footer className="dialog-footer">
            <Button size='small' onClick={ () => this.setState({ showHotVisible: false,}) }>关闭</Button>
          </Dialog.Footer>
        </Dialog>
        <div className='hotIcon'  onClick={this.showHot}>
          <p>分享职位</p>
          <p>接单说明</p>
        </div>


      </div>
    )
  }
}
export default withContractGuard(Home)

